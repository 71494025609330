import { HOST_API } from "../../../config";
import PropTypes from "prop-types";
import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
// @mui
import { styled, useTheme } from "@mui/material/styles";
import {
  Box,
  Stack,
  Drawer,
  Card,
  Typography,
  Avatar,
  Divider,
  Link,
  Button,
} from "@mui/material";
// hooks
import useResponsive from "../../../hooks/useResponsive";
import useCollapseDrawer from "../../../hooks/useCollapseDrawer";
// utils
import cssStyles from "../../../utils/cssStyles";
// config
import { NAVBAR } from "../../../config";
// components
import Logo from "../../../components/Logo";
import IconLogo from "../../../components/IconLogo";
import Scrollbar from "../../../components/Scrollbar";
import { NavSectionVertical } from "../../../components/nav-section";
import Iconify from "src/components/Iconify";
import CallIcon from "@mui/icons-material/Call";
import EmailIcon from "@mui/icons-material/Email";
import UserAccount from "src/assets/images/useraccountbackgroundimg.svg";
//
import navConfig from "./NavConfig";
import CollapseButton from "./CollapseButton";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import { capitalize, upperCase } from "lodash";

// ----------------------------------------------------------------------

const RootStyle = styled("div")(({ theme }) => ({
  [theme.breakpoints.up("lg")]: {
    flexShrink: 0,
    transition: theme.transitions.create("width", {
      duration: theme.transitions.duration.shorter,
    }),
  },
}));

// ----------------------------------------------------------------------

NavbarVertical.propTypes = {
  isOpenSidebar: PropTypes.bool,
  onCloseSidebar: PropTypes.func,
  landingLogo: PropTypes.string,
  emovurIcon: PropTypes.string,
};

export default function NavbarVertical({
  isOpenSidebar,
  onCloseSidebar,
  landingLogo,
  emovurIcon,
}) {
  const theme = useTheme();

  const { pathname } = useLocation();

  const isDesktop = useResponsive("up", "lg");

  const [mobileNumber, setMobileNumber] = useState("");

  const { org_detail } = mobileNumber || {};
  const { primary_am } = org_detail || {};
  const { user } = primary_am || {};
  const { mobile } = user || {};

  // Retrieve the value from localStorage when the component mounts
  useEffect(() => {
    const storedValue = JSON.parse(localStorage.getItem("profile"));
    if (storedValue) {
      setMobileNumber(storedValue);
    }
  }, []); // Empty dependency array to run the effect only once on mount

  const {
    isCollapse,
    collapseClick,
    collapseHover,
    onToggleCollapse,
    onHoverEnter,
    onHoverLeave,
  } = useCollapseDrawer();

  useEffect(() => {
    if (isOpenSidebar) {
      onCloseSidebar();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname]);

  // function stringAvatar(name) {
  //   return {
  //     sx: {
  //       bgcolor: "primary.lighter",
  //       color: "#2065D1",
  //       p: 3,
  //       m: 0,
  //     },
  //     children: `${name.split(" ")[0][0]}`,
  //   };
  // }
  const renderContent = (
    <Scrollbar
      sx={{
        height: 1,
        "& .simplebar-content": {
          height: 1,
          display: "flex",
          flexDirection: "column",
        },
      }}
    >
      <Stack
        spacing={3}
        sx={{
          pt: 2,
          pb: 2,
          px: 2.5,
          flexShrink: 0,
          ...(isCollapse && { alignItems: "center" }),
        }}
      >
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
        >
          {!isCollapse && <Logo landingLogo={landingLogo} />}
          {isCollapse && <IconLogo landingLogo={emovurIcon} />}

          {isDesktop && !isCollapse && (
            <CollapseButton
              onToggleCollapse={onToggleCollapse}
              collapseClick={collapseClick}
            />
          )}
        </Stack>
      </Stack>
      <NavSectionVertical navConfig={navConfig} isCollapse={isCollapse} />
      <Divider sx={{ my: 1 }} />
      {isCollapse &&
        JSON.parse(localStorage.getItem("profile"))?.org_detail?.primary_am !==
          null && (
          <Stack alignItems={"center"}>
            <Avatar
              alt={
                JSON.parse(localStorage.getItem("profile"))?.org_detail
                  ?.primary_am?.user?.name
              }
              src={`${HOST_API}/lms_user/public/${
                JSON.parse(localStorage.getItem("profile"))?.org_detail
                  ?.primary_am?.user?.image
              }`}
            />
          </Stack>
        )}
      {/* {!isCollapse &&
        JSON.parse(localStorage.getItem("profile")).org_detail?.primary_am !==
          null && ( */}
      {/* // <Box sx={{ p: 2.5 }}> */}
      {/* <Avatar
              sx={{
                width: 50,
                height: 50,
                position: "relative",
                left: "40%",
                top: "15%",
                zIndex: 9,
                backgroundColor: "#0c9ffa",
                color: "#fff",
              }}
              alt={
                JSON.parse(localStorage.getItem("profile")).org_detail
                  ?.primary_am?.user?.name
              }
              src={`${HOST_API}/lms_user/public/${
                JSON.parse(localStorage.getItem("profile")).org_detail
                  ?.primary_am?.user?.image
              }`}
            /> */}
      {/* <Card sx={{ p: 1 }}> */}
      {/* <Stack */}
      {/* // direction="row"
              //   alignItems="center"
              //   sx={{ mb: 2 }}
              // > */}
      {/* <Avatar {...stringAvatar("Chethan Kumar")} /> */}
      {/* <Box sx={{ mt: "15%" }}>
                  <Typography
                    variant="subtitle1"
                    textAlign="center"
                    sx={{
                      width: isCollapse ? 0 : "100%",
                      opacity: isCollapse ? "0" : "1",
                      transition:
                        "width 1s cubic-bezier(0.4, 0, 0.2, 1) 0ms,opacity 1s cubic-bezier(0.4, 0, 0.2, 1) 0ms",
                    }}
                  >
                    {
                      JSON.parse(localStorage.getItem("profile")).org_detail
                        ?.primary_am?.user?.name
                    }
                  </Typography>
                  <Typography
                    variant="body2"
                    sx={{
                      width: isCollapse ? 0 : "100%",
                      textAlign: "center",
                      opacity: isCollapse ? "0" : "1",
                      transition:
                        "width 1s cubic-bezier(0.4, 0, 0.2, 1) 0ms,opacity 1s cubic-bezier(0.4, 0, 0.2, 1) 0ms",
                    }}
                  >
      Relationship manager
                  </Typography>
                </Box> */}
      {/* </Stack> */}
      {/* <Divider sx={{ my: 1 }} /> */}
      {/* <Stack direction="row">
                <Link href="tel:+917411251394"> */}
      {/* <Iconify icon={"eva:p hone-call-outline"} /> */}
      {/* <Typography variant="body2" sx={{ ml: 1 }}>
                    {
                      JSON.parse(localStorage.getItem("profile")).org_detail
                        ?.primary_am?.user?.mobile
                    }
                  </Typography> */}
      {/* <Button sx={{ color: "success.main" }} fullWidth>
                    <CallIcon />
                  </Button>
                </Link> */}
      {/* <Link href="mailto:chethan@emovur.com"> */}
      {/* <Iconify icon={"eva:email-outline"} />
                  <Typography variant="body2" sx={{ ml: 1 }}>
                    {
                      JSON.parse(localStorage.getItem("profile")).org_detail
                        ?.primary_am?.user?.email
                    }
                  </Typography> */}
      {/* <Button fullWidth>
                    <EmailIcon />
                  </Button>
                </Link>
              </Stack> */}
      {/* <Stack direction="row">
                <Button sx={{ color: "success.main" }} fullWidth>
                  <Link href="tel:+917411251394">
                    <CallIcon />
                  </Link>
                </Button>
                <Button sx={{ color: "success.main" }} fullWidth>
                  <Link href="mailto:chethan@emovur.com">
                    <EmailIcon />
                  </Link>
                </Button>
              </Stack> */}
      {/* </Card>
          </Box>
        )} */}
      {/* <Box sx={{ flexGrow: 1 }} /> */}
      {/* <Box sx={{ m: 1 }}>
        <img src={UserAccount} alt="logo" />
      </Box> */}
      {/* <Box sx={{ m: 1 }}>
        <img src={UserAccountpart1} alt="logo" />
      </Box> */}
      {!isCollapse &&
        JSON.parse(localStorage.getItem("profile"))?.org_detail?.primary_am !==
          null && (
          <Box sx={{ m: 1 }}>
            <img src={UserAccount} alt="userAccount" />
            <Box
              sx={{
                mt: "15%",
                position: "relative",
                left: "46%",
                bottom: "37%",
                zIndex: 9,
              }}
            >
              <Typography
                variant="subtitle1"
                sx={{
                  fontWeight: "800",
                  width: isCollapse ? 0 : "100%",
                  opacity: isCollapse ? "0" : "1",
                  transition:
                    "width 1s cubic-bezier(0.4, 0, 0.2, 1) 0ms,opacity 1s cubic-bezier(0.4, 0, 0.2, 1) 0ms",
                }}
              >
                {upperCase(
                  JSON.parse(localStorage.getItem("profile"))?.org_detail
                    ?.primary_am?.user?.name
                )}
              </Typography>
              <Typography
                variant="caption"
                display="block"
                gutterBottom
                sx={{
                  width: isCollapse ? 0 : "100%",
                  opacity: isCollapse ? "0" : "1",
                  transition:
                    "width 1s cubic-bezier(0.4, 0, 0.2, 1) 0ms,opacity 1s cubic-bezier(0.4, 0, 0.2, 1) 0ms",
                }}
              >
                Relationship manager
              </Typography>
            </Box>
            <Avatar
              sx={{
                width: 65,
                height: 65,
                zIndex: 9,
                position: "relative",
                left: "10.5%",
                bottom: "45%",
                backgroundColor: "#0c9ffa",
                color: "#fff",
              }}
              alt={
                JSON.parse(localStorage.getItem("profile"))?.org_detail
                  ?.primary_am?.user?.name
              }
              src={`${HOST_API}/lms_user/public/${
                JSON.parse(localStorage.getItem("profile"))?.org_detail
                  ?.primary_am?.user?.image
              }`}
            />
            <Stack
              direction="row"
              alignItems="center"
              spacing={1}
              sx={{ position: "relative", bottom: "56%", left: "43%" }}
            >
              <Link href={`tel:+91${mobile}`} target="_blank">
                <Box
                  sx={{
                    p: 0.5,
                    display: "flex",
                    color: "#2065D1",
                    boxShadow: "0px 0px 5px #f0f0f0",
                    borderRadius: "8px",
                    cursor: "pointer",
                    ":hover": {
                      transform: "scale(1.05)",
                    },
                  }}
                >
                  <CallIcon sx={{ p: 0.3 }} />
                </Box>
              </Link>
              <Link
                href={`https://wa.me/${mobile}`}
                underline="none"
                target="_blank"
              >
                <Box
                  sx={{
                    display: "flex",
                    p: 0.5,
                    boxShadow: "0px 0px 5px #f0f0f0",
                    borderRadius: "8px",
                    alignItems: "center",
                    cursor: "pointer",
                    ":hover": {
                      transform: "scale(1.05)",
                    },
                  }}
                >
                  <WhatsAppIcon color="success" sx={{ p: 0.2 }} />
                  <Typography variant="caption" sx={{ ml: 0.5 }}>
                    WhatsApp
                  </Typography>
                </Box>
              </Link>
            </Stack>
          </Box>
        )}
    </Scrollbar>
  );

  return (
    <RootStyle
      sx={{
        width: {
          lg: isCollapse
            ? NAVBAR.DASHBOARD_COLLAPSE_WIDTH
            : NAVBAR.DASHBOARD_WIDTH,
        },
        ...(collapseClick && {
          position: "absolute",
        }),
      }}
    >
      {!isDesktop && (
        <Drawer
          open={isOpenSidebar}
          onClose={onCloseSidebar}
          PaperProps={{ sx: { width: NAVBAR.DASHBOARD_WIDTH } }}
        >
          {renderContent}
        </Drawer>
      )}

      {isDesktop && (
        <Drawer
          open
          variant="persistent"
          onMouseEnter={onHoverEnter}
          onMouseLeave={onHoverLeave}
          PaperProps={{
            sx: {
              width: NAVBAR.DASHBOARD_WIDTH,
              borderRightStyle: "dashed",
              bgcolor: "background.default",
              transition: (theme) =>
                theme.transitions.create("width", {
                  duration: theme.transitions.duration.standard,
                }),
              ...(isCollapse && {
                width: NAVBAR.DASHBOARD_COLLAPSE_WIDTH,
              }),
              ...(collapseHover && {
                ...cssStyles(theme).bgBlur(),
                boxShadow: (theme) => theme.customShadows.z24,
              }),
            },
          }}
        >
          {renderContent}
        </Drawer>
      )}
    </RootStyle>
  );
}
