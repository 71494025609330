// react
import { createContext, useEffect, useReducer } from "react";
import { Outlet } from "react-router-dom";
// utils
import axios from "src/utils/axios";
import { isValidToken } from "src/utils/jwt";
// Initial State
const initialState = {
  requestList: [],
  apiKey: "",
};

// Action handlers
const handlers = {
  INITIALIZE: (state, action) => {
    const { requestList, apiKey } = action.payload;
    return {
      ...state,
      requestList,
      apiKey: apiKey || "",
    };
  },
  NEW_REPORT: (state, action) => {
    const { requestList } = action.payload;
    return {
      ...state,
      requestList,
    };
  },
};

// Create reducer
const reducer = (state, action) =>
  handlers[action.type] ? handlers[action.type](state, action) : state;

// Create Context
export const GeoGridContext = createContext();

// Provider Component
export const GeoGridContextProvider = () => {
  const [state, dispatch] = useReducer(reducer, initialState);
  const initialize = async () => {
    try {
      // const accessToken = localStorage.getItem("accessToken");
      const controller = new AbortController();
      // if (accessToken && isValidToken(accessToken)) {
      const response = await axios({
        method: "get",
        url: `/lms_user/api/gmb/geogrid/requests`,
        // headers: {
        //   Authorization: `Bearer ${accessToken}`,
        // },
      });
      const { requestList } = await response.data;
      // cancel the request
      controller.abort();
      const apiKeyResponse = await axios({
        method: "get",
        url: `/lms_user/api/gmb/map_api_keys`,
        // headers: {
        //   Authorization: `Bearer ${accessToken}`,
        // },
      });
      const { api_keys } = await apiKeyResponse.data;
      dispatch({
        type: "INITIALIZE",
        payload: {
          requestList,
          apiKey: api_keys[0].api_key,
        },
      });
      // } else {
      //   dispatch({
      //     type: "INITIALIZE",
      //     payload: {
      //       requestList: [],
      //       apiKey: "",
      //     },
      //   });
      // }
    } catch (err) {
      console.error(err);
      dispatch({
        type: "INITIALIZE",
        payload: {
          requestList: [],
          apiKey: "",
        },
      });
    }
  };

  useEffect(() => {
    initialize();
  }, []);

  const newReportRequest = async (
    reportName,
    locationIds,
    keywords,
    gridSize,
    distance,
    requestType,
    edgeDistance
  ) => {
    try {
      // const accessToken = localStorage.getItem("accessToken");
      const response = await axios({
        method: "post",
        url: `/lms_user/api/gmb/geogrid/new/request`,
        // headers: {
        //   Authorization: `Bearer ${accessToken}`,
        // },
        params: {
          report_name: reportName,
          locations: locationIds.toString(),
          keywords: keywords,
          grid_size: gridSize,
          point_distance: distance,
          request_type: requestType,
          edge_distance: edgeDistance,
        },
      });
      const { requestList } = await response.data;

      dispatch({
        type: "NEW_REPORT",
        payload: {
          requestList,
        },
      });
    } catch (error) {
      throw new Error(JSON.stringify(error));
    }
  };

  const getRequestDetails = async (requestId) => {
    try {
      // const accessToken = localStorage.getItem("accessToken");
      const response = await axios({
        method: "get",
        url: `/lms_user/api/gmb/geogrid/request_details/${requestId}`,
        // headers: {
        //   Authorization: `Bearer ${accessToken}`,
        // },
      });
      const keywords = await response.data.keywords;
      const reports = await response.data.reports;
      return { keywords, reports };
    } catch (error) {
      throw new Error(JSON.stringify(error));
    }
  };

  const getRequestKeywords = async (requestId) => {
    try {
      // const accessToken = localStorage.getItem("accessToken");
      const response = await axios({
        method: "get",
        url: `/lms_user/api/gmb/geogrid/request_details/${requestId}`,
        // headers: {
        //   Authorization: `Bearer ${accessToken}`,
        // },
        params: { report: false },
      });
      const { keywords } = await response.data;
      return keywords;
    } catch (error) {
      throw new Error(JSON.stringify(error));
    }
  };

  const getReportDetails = async (requestId, keywords, reportId) => {
    try {
      // const accessToken = localStorage.getItem("accessToken");
      const response = await axios({
        method: "post",
        url: `/lms_user/api/gmb/geogrid/request_report_details`,
        // headers: {
        //   Authorization: `Bearer ${accessToken}`,
        // },
        params: {
          request_id: requestId,
          keywords: keywords.toString(),
          report_id: reportId,
        },
      });
      const { report_details } = await response.data;
      return report_details;
    } catch (error) {
      throw new Error(JSON.stringify(error));
    }
  };

  const locationReportDetails = async (
    requestId,
    reportId,
    locationId,
    keywordId
  ) => {
    try {
      // const accessToken = localStorage.getItem("accessToken");
      const response = await axios({
        method: "post",
        url: `/lms_user/api/gmb/geogrid/location_report_details`,
        // headers: {
        //   Authorization: `Bearer ${accessToken}`,
        // },
        params: {
          request_id: requestId,
          report_id: reportId,
          location_id: locationId,
          keyword_id: keywordId,
        },
      });
      const { location_report, reports, keywords } = await response.data;
      return { locReport: location_report, reports, locKeywords: keywords };
    } catch (error) {
      throw new Error(JSON.stringify(error));
    }
  };

  return (
    <GeoGridContext.Provider
      value={{
        requestList: state.requestList,
        apiKey: state.apiKey,
        newReportRequest,
        getRequestDetails,
        getReportDetails,
        locationReportDetails,
        getRequestKeywords,
      }}
    >
      <Outlet />
      {/* {children} */}
    </GeoGridContext.Provider>
  );
};
