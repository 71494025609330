import axios from "src/utils/axios";
import { createContext } from "react";
import { Outlet } from "react-router-dom";
// import axios from "src/utils/axios";
import { isValidToken } from "src/utils/jwt";
import { HOST_API } from "src/config";

export const ChatReportContext = createContext();

export const ChatReportContextProvider = () => {
  const getChatReport = async (dateRange) => {
    try {
      // const accessToken = localStorage.getItem("accessToken");
      // if (accessToken && isValidToken(accessToken)) {
      const response = await axios({
        method: "get",
        url: `${HOST_API}/lms_chat/api/chat_report`,
        headers: {
          "x-user-id": localStorage.getItem("_id"),
        },
        params: { date_range: dateRange || "" },
      });
      const typeSource = response.data.source_wise;
      const typeStatus = response.data.status_wise;
      return { typeSource, typeStatus };
      // }
    } catch (error) {
      throw new Error(JSON.stringify(error));
    }
  };

  const getChatAnalytics = async () => {
    try {
      // const accessToken = localStorage.getItem("accessToken");
      // if (accessToken && isValidToken(accessToken)) {
      const response = await axios({
        method: "get",
        url: `${HOST_API}/lms_chat/api/chat_analytics`,
        headers: {
          "x-user-id": localStorage.getItem("_id"),
        },
      });
      const { total, source_wise, status_wise } = response.data;
      return { total, source: source_wise, status: status_wise };
      // }
    } catch (error) {
      throw new Error(JSON.stringify(error));
    }
  };
  return (
    <ChatReportContext.Provider value={{ getChatReport, getChatAnalytics }}>
      <Outlet />
    </ChatReportContext.Provider>
  );
};
