// react
import { createContext, useEffect, useReducer } from "react";
import { Outlet } from "react-router-dom";
// utils
import { axiosInstance2 } from "src/utils/axios";
import { isValidToken } from "../../../utils/jwt";
import { updateUserApi } from "src/services/register/updateUser";
import { createUser } from "src/services/register/createUser";
import { addUserToOrg } from "src/services/register/addUserToOrg";

// Initial State
const initialState = {
  users: [],
};

// Action handlers
const handlers = {
  INITIALIZE: (state, action) => {
    const { users } = action.payload;
    return {
      ...state,
      users,
    };
  },
  CREATE_USER: (state, action) => {
    const { user } = action.payload;
    return {
      ...state,
      users: [...state.users, user],
    };
  },
  UPDATE_USER: (state, action) => {
    const { user } = action.payload;
    const updated = [...state.users];
    const toUpdate = updated.findIndex(
      (userItem) => userItem.user_id === user.user_id
    );
    updated[toUpdate] = user;
    return {
      ...state,
      users: updated,
    };
  },
  CHANGE_USER_STATUS: (state, action) => {
    const { user } = action.payload;
    const updated = [...state.users];
    const toUpdate = updated.findIndex(
      (userItem) => userItem.user_id === user.user_id
    );
    updated[toUpdate].status = user.status;
    return {
      ...state,
      users: updated,
    };
  },
  MAKE_ADMIN: (state, action) => {
    const { users } = action.payload;
    const updated = [...state.users];
    const toUpdate = updated.findIndex(
      (userItem) => userItem.user_id === users.user_id
    );
    updated[toUpdate].sub_type = users.sub_type;
    return {
      ...state,
      users: updated,
    };
  },
  ADD_USER_NODE: (state, action) => {
    const { userID, nodeID } = action.payload;
    const updated = [...state.users];
    const toUpdate = updated.findIndex(
      (userItem) => userItem.user_id === userID
    );
    updated[toUpdate].node_id = nodeID;
    return {
      ...state,
      users: updated,
    };
  },
};

// Create reducer
const reducer = (state, action) =>
  handlers[action.type] ? handlers[action.type](state, action) : state;

// Create Context
export const UsersContext = createContext();

// Provider Component
export const UsersProvider = () => {
  const [state, dispatch] = useReducer(reducer, initialState);

  const initialize = async () => {
    try {
      // const accessToken = localStorage.getItem("accessToken");
      const orgID = JSON.parse(localStorage.getItem("profile")).org_id;
      const controller = new AbortController();
      // if (accessToken && isValidToken(accessToken)) {
      const response = await axiosInstance2({
        method: "get",
        url: "/lms_user/api/users",
        params: { org_id: orgID },
      });
      const { users } = await response.data;
      // cancel the request
      controller.abort();
      dispatch({
        type: "INITIALIZE",
        payload: {
          users,
        },
      });
      // } else {
      //   dispatch({
      //     type: "INITIALIZE",
      //     payload: {
      //       users: [],
      //     },
      //   });
      // }
    } catch (err) {
      console.error(err);
      dispatch({
        type: "INITIALIZE",
        payload: {
          users: [],
        },
      });
    }
  };
  useEffect(() => {
    initialize();
  }, []);

  const createUserHandler = async (userData) => {
    try {
      // creating user
      const userFormData = new FormData();
      userFormData.append("full_name", userData.name);
      userFormData.append("email", userData.email);
      userFormData.append("country_code", "+91");
      userFormData.append("phone", userData.phoneNumber);
      userFormData.append("password", "123456789");

      const MongoUser = await createUser(userFormData);

      // adding user to org
      const obj = {
        user: MongoUser._id,
        partner: JSON.parse(localStorage.getItem("profile"))?.reseller_details
          ?.mongo_resellerid,
        role: "admin",
      };
      const rel = await addUserToOrg(
        obj,
        JSON.parse(localStorage.getItem("profile"))?.org_detail?.mongo_org_id
      );

      // const accessToken = localStorage.getItem("accessToken");
      const orgID = JSON.parse(localStorage.getItem("profile")).org_id;
      const resellerID = JSON.parse(
        localStorage.getItem("profile")
      ).reseller_id;
      const data = new FormData();
      data.append("user_name", userData.name);
      data.append("email", userData.email);
      data.append("mobile", userData.phoneNumber);
      data.append("reseller_id", resellerID);
      data.append("org_id", orgID);
      data.append("password", "123456789");
      const response = await axiosInstance2({
        method: "post",
        url: "/lms_user/api/users",
        headers: {
          "Content-Type": "multipart/form-data",
          // Authorization: `Bearer ${accessToken}`,
        },
        data: data,
      });
      const { user } = await response.data;
      dispatch({
        type: "CREATE_USER",
        payload: {
          user,
        },
      });
    } catch (error) {
      throw new Error(JSON.stringify(error));
    }
  };

  const updateUser = async (userData, userID, mongoUserId) => {
    try {
      // update user
      const userFormData = new FormData();
      userFormData.append("full_name", userData.name);
      userFormData.append("email", userData.email);
      userFormData.append("country_code", "+91");
      userFormData.append("phone", userData.phoneNumber);
      userFormData.append("password", "123456789");

      const MongoUser = await updateUserApi(userFormData, mongoUserId);

      // const accessToken = localStorage.getItem("accessToken");
      const orgID = JSON.parse(localStorage.getItem("profile")).org_id;
      const resellerID = JSON.parse(
        localStorage.getItem("profile")
      ).reseller_id;
      const data = new FormData();
      data.append("user_name", userData.name);
      data.append("email", userData.email);
      data.append("mobile", userData.phoneNumber);
      data.append("reseller_id", resellerID);
      data.append("org_id", orgID);
      data.append("password", "123456789");
      data.append("_method", "patch");
      const response = await axiosInstance2({
        method: "post",
        url: `/lms_user/api/users/${userID}`,
        // headers: {
        //   Authorization: `Bearer ${accessToken}`,
        // },
        data: data,
      });
      const { user } = await response.data;
      dispatch({
        type: "UPDATE_USER",
        payload: {
          user,
        },
      });
    } catch (error) {
      throw new Error(JSON.stringify(error));
    }
  };

  const addUserToNode = async (userID, nodeID) => {
    try {
      // const accessToken = localStorage.getItem("accessToken");
      const orgID = JSON.parse(localStorage.getItem("profile")).org_id;
      const resellerID = JSON.parse(
        localStorage.getItem("profile")
      ).reseller_id;
      await axiosInstance2({
        method: "get",
        url: `/lms_user/api/node_users`,
        // headers: {
        //   Authorization: `Bearer ${accessToken}`,
        // },
        params: {
          reseller_id: resellerID,
          org_id: orgID,
          user_id: userID,
          node_id: nodeID,
        },
      });
      dispatch({
        type: "ADD_USER_NODE",
        payload: {
          userID,
          nodeID,
        },
      });
    } catch (error) {
      throw new Error(JSON.stringify(error));
    }
  };

  const changeUserStatus = async (userID) => {
    try {
      // const accessToken = localStorage.getItem("accessToken");
      const response = await axiosInstance2({
        method: "get",
        url: `/lms_user/api/user_status/${userID}`,
        // headers: {
        //   Authorization: `Bearer ${accessToken}`,
        // },
      });
      const { user } = await response.data;
      dispatch({
        type: "CHANGE_USER_STATUS",
        payload: {
          user,
        },
      });
    } catch (error) {
      throw new Error(JSON.stringify(error));
    }
  };

  const makeAdmin = async (userID) => {
    try {
      // const accessToken = localStorage.getItem("accessToken");
      const response = await axiosInstance2({
        method: "post",
        url: `/lms_user/api/user_to_admin`,
        // headers: {
        //   Authorization: `Bearer ${accessToken}`,
        // },
        params: {
          user_id: userID,
        },
      });
      const { users } = await response.data;
      dispatch({
        type: "MAKE_ADMIN",
        payload: {
          users,
        },
      });
    } catch (error) {
      throw new Error(JSON.stringify(error));
    }
  };

  return (
    <UsersContext.Provider
      value={{
        users: state.users,
        createUserHandler,
        updateUser,
        changeUserStatus,
        addUserToNode,
        makeAdmin,
      }}
    >
      <Outlet />
      {/* {children} */}
    </UsersContext.Provider>
  );
};
