// utils
import axios from "src/utils/axios";

import { isValidToken } from "src/utils/jwt";
import { HOST_API } from "src/config";

export default async function getBotTriggers() {
  try {
    // const accessToken = localStorage.getItem("accessToken");
    // if (isValidToken(accessToken)) {
    const response = await axios({
      method: "get",
      url: `${HOST_API}/lms_chat/api/bot/trigger_response`,
      headers: {
        "x-user-id": localStorage.getItem("_id"),
      },
    });
    const res = await response.data;
    return res;
    // }
  } catch (error) {
    throw new Error(Object.keys(error.errors).map((err) => error.errors[err]));
  }
}
