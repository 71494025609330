import axios from "src/utils/axios";
import { isValidToken } from "src/utils/jwt";

export default async function createThirdPartyConnectors(thirdPartyConnectors) {
  try {
    const accessToken = localStorage.getItem("accessToken");
    if (isValidToken(accessToken)) {
      const response = await axios({
        method: "post",
        url: `/lms_campaign/api/whatsapp/connector`,
        params: {
          name: thirdPartyConnectors,
        },
      });
      const res = await response.data;
      return res;
    }
  } catch (error) {
    throw new Error(error?.errors?.name.map((err) => err));
  }
}
