import { createContext, useReducer, useEffect } from "react";
import { Outlet } from "react-router-dom";
import createIntent from "src/services/whatsApp/dialogflow/createIntent";
import deleteIntent from "src/services/whatsApp/dialogflow/deleteIntent";
import updateIntent from "src/services/whatsApp/dialogflow/updateIntent";
import axios from "src/utils/axios";
import { isValidToken } from "src/utils/jwt";

const initialState = {
  account: null,
  loginUrl: null,
};

// create context
export const DialogflowContext = createContext();

// Action handlers
const handlers = {
  INITIALIZE: (state, action) => {
    const { account, loginUrl } = action.payload;
    return {
      ...state,
      account,
      loginUrl,
    };
  },
};

// Create reducer function
const reducer = (state, action) =>
  handlers[action.type] ? handlers[action.type](state, action) : state;

// Create context provider
export const DialogflowContextProvider = () => {
  const [state, dispatch] = useReducer(reducer, initialState);

  const initialize = async () => {
    try {
      // const accessToken = localStorage.getItem("accessToken");
      const controller = new AbortController();
      // if (accessToken && isValidToken(accessToken)) {
      const response = await axios.get(
        `lms_chat/api/google/dialogflow/account`,
        { signal: controller.signal }
        // {
        //   headers: {
        //     Authorization: `Bearer ${accessToken}`,
        //   },
        // }
      );
      const { account, login_url } = await response.data;
      // cancel the request
      controller.abort();
      dispatch({
        type: "INITIALIZE",
        payload: {
          account: account ? account : null,
          loginUrl: login_url ? login_url : null,
        },
      });
      // } else {
      //   dispatch({
      //     type: "INITIALIZE",
      //     payload: { account: null, loginUrl: null },
      //   });
      // }
    } catch (error) {
      console.log(error);
    }
  };

  const setupDialogflowAccount = async (setupData) => {
    try {
      // const accessToken = localStorage.getItem("accessToken");
      // if (accessToken && isValidToken(accessToken)) {
      await axios({
        method: "post",
        url: `/lms_chat/api/google/dialogflow/account-setup`,
        // headers: {
        //   Authorization: `Bearer ${accessToken}`,
        // },
        data: setupData,
      });
      await initialize();
      // }
    } catch (error) {
      throw new Error(JSON.stringify(error));
    }
  };

  const updateProjectId = async (projectId) => {
    try {
      // const accessToken = localStorage.getItem("accessToken");
      const controller = new AbortController();
      // if (accessToken && isValidToken(accessToken)) {
      const response = await axios({
        method: "post",
        url: `lms_chat/api/google/dialogflow/project`,
        // headers: {
        //   Authorization: `Bearer ${accessToken}`,
        // },
        params: { project: projectId },
      });
      const { account } = await response.data;
      // cancel the request
      controller.abort();
      dispatch({
        type: "INITIALIZE",
        payload: {
          account: account ? account : null,
          loginUrl: null,
        },
      });
      // } else {
      //   return null;
      // }
    } catch (error) {
      throw new Error(JSON.stringify(error));
    }
  };

  const getIntents = async () => {
    try {
      // const accessToken = localStorage.getItem("accessToken");
      const controller = new AbortController();
      // if (accessToken && isValidToken(accessToken)) {
      const response = await axios({
        method: "get",
        url: `lms_chat/api/google/dialogflow/intents`,
        // headers: {
        //   Authorization: `Bearer ${accessToken}`,
        // },
      });
      const { intents } = await response.data;
      // cancel the request
      controller.abort();
      return intents;
      // } else {
      //   return null;
      // }
    } catch (error) {
      throw new Error(JSON.stringify(error));
    }
  };

  const updateIntentStatus = async (status) => {
    try {
      // const accessToken = localStorage.getItem("accessToken");
      const controller = new AbortController();
      // if (accessToken && isValidToken(accessToken)) {
      const response = await axios({
        method: "post",
        url: `lms_chat/api/google/dialogflow/intent_status`,
        // headers: {
        //   Authorization: `Bearer ${accessToken}`,
        // },
        params: status,
      });
      const { intents } = await response.data;
      // cancel the request
      controller.abort();
      return intents;
      // } else {
      //   return null;
      // }
    } catch (error) {
      throw new Error(JSON.stringify(error));
    }
  };

  const getIntentAnalytics = async (range) => {
    try {
      // const accessToken = localStorage.getItem("accessToken");
      const controller = new AbortController();
      // if (accessToken && isValidToken(accessToken)) {
      const response = await axios({
        method: "get",
        url: `lms_chat/api/google/dialogflow/intent_analytics_report`,
        // headers: {
        //   Authorization: `Bearer ${accessToken}`,
        // },
        params: { date_range: range ? range : null },
      });
      const { intents } = await response.data;
      // cancel the request
      controller.abort();
      return intents;
      // } else {
      //   return null;
      // }
    } catch (error) {
      throw new Error(JSON.stringify(error));
    }
  };

  const createIntentContext = async (
    data,
    responseType,
    interactiveType,
    interactiveButtonsHeaderType,
    interactiveButtonsCounter,
    flowId,
    flowHeaderType,
    optionsArray
  ) => {
    try {
      const controller = new AbortController();
      const { intents } = await createIntent(
        data,
        responseType,
        interactiveType,
        interactiveButtonsHeaderType,
        interactiveButtonsCounter,
        flowId,
        flowHeaderType,
        optionsArray
      );
      // cancel the request
      controller.abort();
      return intents;
    } catch (error) {
      console.log(error);
      throw new Error(JSON.stringify(error));
    }
  };

  const updateIntentContext = async (
    data,
    responseType,
    intentId,
    interactiveType,
    interactiveButtonsHeaderType,
    interactiveButtonsCounter,
    flowId,
    flowHeaderType,
    optionsArray
  ) => {
    try {
      const controller = new AbortController();
      const { intents } = await updateIntent(
        data,
        responseType,
        intentId,
        interactiveType,
        interactiveButtonsHeaderType,
        interactiveButtonsCounter,
        flowId,
        flowHeaderType,
        optionsArray
      );
      // cancel the request
      controller.abort();
      return intents;
    } catch (error) {
      throw new Error(JSON.stringify(error));
    }
  };

  const deleteIntentContext = async (intentId) => {
    try {
      const controller = new AbortController();
      const { intents } = await deleteIntent(intentId);
      // cancel the request
      controller.abort();
      return intents;
    } catch (error) {
      throw new Error(JSON.stringify(error));
    }
  };

  useEffect(() => {
    initialize();
  }, []);

  return (
    <DialogflowContext.Provider
      value={{
        account: state.account,
        loginUrl: state.loginUrl,
        setupDialogflowAccount,
        updateProjectId,
        getIntents,
        updateIntentStatus,
        getIntentAnalytics,
        createIntentContext,
        updateIntentContext,
        deleteIntentContext,
      }}
    >
      <Outlet />
    </DialogflowContext.Provider>
  );
};
